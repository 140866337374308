/* LAYOUT */

.Block--homeFeatured {
  padding-top: 5.8rem;
  padding-bottom: 0;
}

.Block__Header--homeFeatured {
  margin: 0;
  margin-bottom: -5px; /* place heading baseline on the top edge of content block */

  text-align: center;
}

.Featured__Content {
  padding-top: 3.4rem;
  padding-bottom: 8rem;

  color: var(--text-inverted);
  background-color: var(--dark);
}

.Featured__Media,
.Featured__Details {
  grid-column: 1 / -1;
}

@media (min-width: 720px) {
  .Block--homeFeatured {
    padding-top: 9.8rem;
    padding-bottom: 0;
  }

  .Featured__Content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

@media (min-width: 1024px) {
  .Featured__Media,
  .Featured__Details {
    grid-row-start: 1;
    grid-column-end: span 6;
  }

  .Featured__Media {
    grid-column-start: 7;
  }

  .Featured__Details {
    grid-column-start: 1;
    padding-right: 3rem;
  }
}

@media (min-width: 1500px) {
  .Block__Header--homeFeatured {
    margin-bottom: -4rem;
  }

  .Block__Title--homeFeatured {
    font-size: 10rem;
    line-height: initial;
  }
}

/* HEADING */

.Block__Title--homeFeatured {
  font-family: var(--font-primary);
  font-size: 5rem;
  line-height: 0.855;
  letter-spacing: -0.01em;
}

@media (min-width: 720px) {
  .Block__Header--homeFeatured {
    /* CSS LOCKS
     * x = browser width
     * y = font size
     * m = (y2 - y1) / (x2 - x1)
     *   = (-10px - -5px) / (1500px - 700px)
     *   = -0.00625;
     * b = y - mx
     *   = -5px - -0.00625 * 700px
     *   = -0.625px
     * y = mx + b
     *   = -0.00625 * 100vw + -0.625px
     *   = -0.625vw + 0.00625rem
     */
    margin-bottom: calc(-0.625vw + 0.00625rem);
  }

  .Block__Title--homeFeatured {
    /* CSS LOCKS
     * x = browser width
     * y = font size
     * m = (y2 - y1) / (x2 - x1)
     *   = (100px - 50px) / (1500px - 700px)
     *   = 0.0625;
     * b = y - mx
     *   = 50px - 0.625 * 700px
     *   = 6.25px
     * y = mx + b
     *   = 0.0625 * 100vw + 6.25px
     *   = 6.25vw + 0.0625rem
     */
    font-size: calc(6.25vw + 0.0625rem);
  }
}

@media (min-width: 1500px) {
  .Block__Header--homeFeatured {
    margin-bottom: -1rem;
  }

  .Block__Title--homeFeatured {
    font-size: 10rem;
  }
}

/* CONTENT */

.Featured__Media {
  aspect-ratio: 2 / 1.4;
}

.Featured__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.Featured__Title {
  margin: 0;
  margin-bottom: 1.4rem;

  font-family: var(--font-primary);
  font-size: 2.6rem;
  line-height: 1.15;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--secondary-light);
}

.Featured__Description {
  margin: 0;

  font-size: 1.8rem;
  line-height: 1.41;
}

.Featured__Buttons {
  margin-top: 3.8rem;
}

@media (min-width: 720px) {
  .Featured__Title {
    margin-bottom: 1.8rem;

    font-size: 3.2rem;
    line-height: 1.2;
  }

  .Featured__Description {
    font-size: 2rem;
    line-height: 1.3;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .Featured__Media::before {
    padding-top: calc(100% / 2 * 1.4);
  }
}
