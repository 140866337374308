/* LAYOUT */

.Block--homeLatestNews {
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
}

.LatestNews__Card {
  display: block;
}

.LatestNews__Media {
  margin-bottom: 2rem;
}

@media (min-width: 720px) {
  .Block--homeLatestNews {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1024px) {
  .LatestNews__Card {
    display: flex;
    align-items: flex-start;
  }

  .LatestNews__Media {
    flex-shrink: 0;
    width: 40%;
    min-width: 30rem;
    max-width: 55rem;
    margin-right: 3.8rem;
    margin-bottom: 0;
  }
}

/* LATEST NEWS ITEM */

.LatestNews__Card {
  position: relative;
}

.LatestNews__Link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  font-size: 0;
  text-decoration: none;
}

.LatestNews__Media {
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.LatestNews__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.LatestNews__Title {
  margin: 0;
  margin-bottom: 1.2rem;

  font-family: var(--font-primary);
  font-size: 2rem;
  line-height: 1.15;
  letter-spacing: -0.01em;
}

.LatestNews__Description {
  margin: 0;
  margin-bottom: 1.6rem;

  font-size: 1.6rem;
  line-height: 1.41;
}

.LatestNews__Tags {
  --tags-text-size: 1.2rem;
}

.LatestNews__Date {
  display: block;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  font-size: 1.2rem;
  line-height: 0.98;
  text-transform: uppercase;
  color: var(--gray-100);
}

.LatestNews__Tag {
  --tags-padding-vertical: 0.6rem;
  --tags-padding-horizontal: 1.4rem;

  z-index: 1;
}

@media (min-width: 720px) {
  .LatestNews__Title {
    font-size: 2.8rem;
  }

  .LatestNews__Description {
    margin-bottom: 1.8rem;

    font-size: 2.2rem;
    line-height: 1.3;
  }

  .LatestNews__Tags {
    --tags-text-size: 1.8rem;
  }

  .LatestNews__Date {
    font-size: 1.8rem;
    line-height: initial;
  }
}

/* SEPARATOR */

.LatestNews__Separator {
  margin: 2.8rem -2rem;
  border: 1px solid var(--gray);
}

@media (min-width: 720px) {
  .LatestNews__Separator {
    margin: 3.8rem 0;
  }
}

/* INTERACTIONS */

.LatestNews__Image {
  transition: transform 250ms ease;
}

.LatestNews__Card:hover .LatestNews__Image,
.LatestNews__Card:focus-within .LatestNews__Image {
  transform: scale(1.05);
}

.LatestNews__Title {
  transition: color 250ms ease;
}

.LatestNews__Card:hover .LatestNews__Title,
.LatestNews__Card:focus-within .LatestNews__Title {
  color: var(--primary-light);
}

@media (prefers-reduced-motion: reduce) {
  .LatestNews__Image {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .LatestNews__Media::before {
    padding-top: calc(100% / 16 * 9);
  }
}
