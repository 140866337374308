/* LAYOUT */

.Block--homeEventCalendar {
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
}

.Block__Header--homeEventCalendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EventCalendar {
  --event-calendar-items: 1;
  --event-calendar-index: 0;
  --event-calendar-items-per-page: 1;
  --event-calendar-items-spacing: 3rem;
  --event-calendar-swipe-offset: 0;
  --event-calendar-item-width: max(
    100% - var(--event-calendar-items-spacing) - 10rem,
    28rem
  );

  overflow: hidden;
}

.EventCalendar__Strip {
  display: flex;
  margin: calc(var(--event-calendar-items-spacing) / 2);
}

.EventCalendar[data-items="1"] .EventCalendar__Strip {
  justify-content: center;
}

.UpcomingEvent {
  width: var(--event-calendar-item-width);
}

@media (min-width: 720px) {
  .Block--homeEventCalendar {
    padding-top: 6.2rem;
    padding-bottom: 6.2rem;
  }

  .EventCalendar {
    --event-calendar-items-per-page: 2;
    --event-calendar-items-spacing: 3rem;
    --event-calendar-item-width: calc(
      (100%
        - (var(--event-calendar-items-spacing)
           * (var(--event-calendar-items-per-page) - 1)))
      / var(--event-calendar-items-per-page)
    );
  }
}

@media (min-width: 1024px) {
  .Block__Header--homeEventCalendar {
    margin-bottom: 3rem;
  }

  .EventCalendar {
    --event-calendar-items-per-page: 3;
  }

  .EventCalendar[data-items="2"] .EventCalendar__Strip {
    justify-content: center;
  }
}

/* CAROUSEL */

.EventCalendar__Strip {
  transform: translateX(
    calc(
      ((var(--event-calendar-item-width) + var(--event-calendar-items-spacing))
      * var(--event-calendar-index)
      * -1)
      + var(--event-calendar-swipe-offset) * 1px
    )
  );
  touch-action: pan-y;
}

.EventCalendar--swiping .EventCalendar__Strip {
  user-select: none;
}

.EventCalendar__Buttons {
  --event-calendar-buttons-spacing: 1rem;

  display: none;
  margin: 0 calc(var(--event-calendar-buttons-spacing) / -2);
}

.EventCalendar__Button {
  margin: 0 calc(var(--event-calendar-buttons-spacing) / 2);
}

.EventCalendar__Button.Button {
  /* extra class selector to increase specificity over buttons.css */
  background-size: calc(var(--button-size) * 0.9);
}

.EventCalendar__Button--previous {
  background-image: url("/static/images/ic-arrow-previous.svg");
}

.EventCalendar__Button--next {
  background-image: url("/static/images/ic-arrow-next.svg");
}

@media (min-width: 720px) {
  .EventCalendar__Buttons {
    --event-calendar-buttons-spacing: 2rem;
    display: flex;
  }
}

@media (min-width: 1024px) {
  .EventCalendar__Buttons {
    --event-calendar-buttons-spacing: 4rem;
  }
}

/* CAROUSEL TRANSITIONS */

.EventCalendar:not(.EventCalendar--swiping) .EventCalendar__Strip {
  transition: transform 250ms ease;
}

@media (prefers-reduced-motion: reduce) {
  .EventCalendar__Strip {
    transition: none !important;
  }
}

/* UPCOMING EVENT ITEMS */

.UpcomingEvent {
  --event-calendar-item-media-opacity: 1;

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray);
  box-shadow: 0.2rem 0.1rem 2.2rem var(--dark-transparent-500);
}

.UpcomingEvent:not(:last-child) {
  margin-right: var(--event-calendar-items-spacing);
}

.UpcomingEvent--unavailable {
  --event-calendar-item-media-opacity: 0.5;
  box-shadow: none;
  cursor: not-allowed;
}

.UpcomingEvent--unavailable .UpcomingEvent__Title,
.UpcomingEvent--unavailable .UpcomingEvent__Status,
.UpcomingEvent--unavailable .UpcomingEvent__Tags {
  opacity: var(--event-calendar-item-media-opacity, 1);
}

.UpcomingEvent__Details {
  position: relative;
  display: block;
  width: 100%;
  padding: 1.4rem;
  padding-bottom: 1.2rem;

  text-decoration: none;
}

.UpcomingEvent__Media {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 4 / 3;
  margin-bottom: 1rem;
  overflow: hidden;
}

.UpcomingEvent__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.UpcomingEvent__Media__Overlay {
  position: absolute;
  padding: 0.8rem 2.4rem;
  margin: 0;
  border-radius: 10rem;

  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--light);
  background-color: var(--dark-transparent-350);
}

.UpcomingEvent__Category {
  margin: 0;
  margin-bottom: 3px;

  font-size: 1.2rem;
  line-height: 1.395;
  text-transform: uppercase;
}

.UpcomingEvent__Title {
  margin: 0;
  margin-bottom: 0.4rem;

  font-family: var(--font-primary);
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.18;
  letter-spacing: -0.01em;
}

.UpcomingEvent__Ongoing {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.2rem 1.2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  border-radius: 0.4rem;

  font-family: var(--font-primary);
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  color: var(--text-primary);
  background-color: var(--secondary-light);
}

.UpcomingEvent__Ongoing::before {
  content: "";
  display: block;
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;
  background: url("/static/images/ic-live-dark.svg") center center no-repeat;
}

.UpcomingEvent__Date,
.UpcomingEvent__Location {
  margin: 0;

  font-size: 1.6rem;
  line-height: 0.98;
  color: var(--gray-100);
}

.UpcomingEvent__Date {
  display: block;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}

.UpcomingEvent__Location {
  margin-top: 6px;
}

.UpcomingEvent__Tags {
  padding: 3rem 1.4rem;
  padding-top: 0;
}

.UpcomingEvent__Tag {
  line-height: 0.98;
  z-index: 1;
}

@media (min-width: 720px) {
  .UpcomingEvent {
    border-width: 2px;
  }

  .UpcomingEvent__Details {
    padding: 2rem;
    padding-bottom: 1.4rem;
  }

  .UpcomingEvent__Media {
    margin-bottom: 2.4rem;
  }

  .UpcomingEvent__Media__Overlay {
    display: none;
    font-size: 2.4rem;
    letter-spacing: 0.03em;
  }

  .UpcomingEvent__Category {
    margin-bottom: 6px;

    font-size: 1.6rem;
    line-height: 0.98;
  }

  .UpcomingEvent__Title {
    margin-bottom: 0.6rem;
    font-size: 2.4rem;
  }

  .UpcomingEvent__Status {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .UpcomingEvent__Ongoing {
    padding: 0.4rem 1.6rem;
    margin-right: 1.6rem;
    font-size: 1.8rem;
  }

  .UpcomingEvent__Ongoing::before {
    width: 2.4rem;
    height: 2.4rem;
  }

  .UpcomingEvent__Date,
  .UpcomingEvent__Location {
    font-size: 1.8rem;
  }

  .UpcomingEvent__Location {
    margin-top: 1rem;
  }

  .UpcomingEvent__Tags {
    padding: 4rem 2rem;
    padding-top: 0;
  }
}

/* INTERACTIONS */

.UpcomingEvent {
  --event-calendar-item-highlight-scale: 1.05;
  --event-calendar-item-highlight-color: var(--primary-light);
}

.UpcomingEvent--unavailable {
  --event-calendar-item-highlight-scale: 1;
  --event-calendar-item-highlight-color: var(--text-primary);
}

.UpcomingEvent__Image {
  transition: transform 250ms ease;
}

.UpcomingEvent__Details:hover .UpcomingEvent__Image,
.UpcomingEvent__Details:focus .UpcomingEvent__Image {
  transform: scale(var(--event-calendar-item-highlight-scale, 1.05));
}

.UpcomingEvent__Details:hover .UpcomingEvent__Media__Overlay,
.UpcomingEvent__Details:focus .UpcomingEvent__Media__Overlay {
  display: block;
}

.UpcomingEvent__Title {
  transition: color 250ms ease;
}

.UpcomingEvent__Details:hover .UpcomingEvent__Title,
.UpcomingEvent__Details:focus .UpcomingEvent__Title {
  color: var(--event-calendar-item-highlight-color, var(--primary-light));
}

@media (prefers-reduced-motion: reduce) {
  .UpcomingEvent__Image {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .UpcomingEvent__Media::before {
    padding-top: calc(100% / 4 * 3);
  }
}

/* LAYOUT */

.Block--homeFeatured {
  padding-top: 5.8rem;
  padding-bottom: 0;
}

.Block__Header--homeFeatured {
  margin: 0;
  margin-bottom: -5px; /* place heading baseline on the top edge of content block */

  text-align: center;
}

.Featured__Content {
  padding-top: 3.4rem;
  padding-bottom: 8rem;

  color: var(--text-inverted);
  background-color: var(--dark);
}

.Featured__Media,
.Featured__Details {
  grid-column: 1 / -1;
}

@media (min-width: 720px) {
  .Block--homeFeatured {
    padding-top: 9.8rem;
    padding-bottom: 0;
  }

  .Featured__Content {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

@media (min-width: 1024px) {
  .Featured__Media,
  .Featured__Details {
    grid-row-start: 1;
    grid-column-end: span 6;
  }

  .Featured__Media {
    grid-column-start: 7;
  }

  .Featured__Details {
    grid-column-start: 1;
    padding-right: 3rem;
  }
}

@media (min-width: 1500px) {
  .Block__Header--homeFeatured {
    margin-bottom: -4rem;
  }

  .Block__Title--homeFeatured {
    font-size: 10rem;
    line-height: initial;
  }
}

/* HEADING */

.Block__Title--homeFeatured {
  font-family: var(--font-primary);
  font-size: 5rem;
  line-height: 0.855;
  letter-spacing: -0.01em;
}

@media (min-width: 720px) {
  .Block__Header--homeFeatured {
    /* CSS LOCKS
     * x = browser width
     * y = font size
     * m = (y2 - y1) / (x2 - x1)
     *   = (-10px - -5px) / (1500px - 700px)
     *   = -0.00625;
     * b = y - mx
     *   = -5px - -0.00625 * 700px
     *   = -0.625px
     * y = mx + b
     *   = -0.00625 * 100vw + -0.625px
     *   = -0.625vw + 0.00625rem
     */
    margin-bottom: calc(-0.625vw + 0.00625rem);
  }

  .Block__Title--homeFeatured {
    /* CSS LOCKS
     * x = browser width
     * y = font size
     * m = (y2 - y1) / (x2 - x1)
     *   = (100px - 50px) / (1500px - 700px)
     *   = 0.0625;
     * b = y - mx
     *   = 50px - 0.625 * 700px
     *   = 6.25px
     * y = mx + b
     *   = 0.0625 * 100vw + 6.25px
     *   = 6.25vw + 0.0625rem
     */
    font-size: calc(6.25vw + 0.0625rem);
  }
}

@media (min-width: 1500px) {
  .Block__Header--homeFeatured {
    margin-bottom: -1rem;
  }

  .Block__Title--homeFeatured {
    font-size: 10rem;
  }
}

/* CONTENT */

.Featured__Media {
  aspect-ratio: 2 / 1.4;
}

.Featured__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.Featured__Title {
  margin: 0;
  margin-bottom: 1.4rem;

  font-family: var(--font-primary);
  font-size: 2.6rem;
  line-height: 1.15;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--secondary-light);
}

.Featured__Description {
  margin: 0;

  font-size: 1.8rem;
  line-height: 1.41;
}

.Featured__Buttons {
  margin-top: 3.8rem;
}

@media (min-width: 720px) {
  .Featured__Title {
    margin-bottom: 1.8rem;

    font-size: 3.2rem;
    line-height: 1.2;
  }

  .Featured__Description {
    font-size: 2rem;
    line-height: 1.3;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .Featured__Media::before {
    padding-top: calc(100% / 2 * 1.4);
  }
}

/* LAYOUT */

.Block--homeLatestNews {
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
}

.LatestNews__Card {
  display: block;
}

.LatestNews__Media {
  margin-bottom: 2rem;
}

@media (min-width: 720px) {
  .Block--homeLatestNews {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1024px) {
  .LatestNews__Card {
    display: flex;
    align-items: flex-start;
  }

  .LatestNews__Media {
    flex-shrink: 0;
    width: 40%;
    min-width: 30rem;
    max-width: 55rem;
    margin-right: 3.8rem;
    margin-bottom: 0;
  }
}

/* LATEST NEWS ITEM */

.LatestNews__Card {
  position: relative;
}

.LatestNews__Link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  font-size: 0;
  text-decoration: none;
}

.LatestNews__Media {
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

.LatestNews__Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.LatestNews__Title {
  margin: 0;
  margin-bottom: 1.2rem;

  font-family: var(--font-primary);
  font-size: 2rem;
  line-height: 1.15;
  letter-spacing: -0.01em;
}

.LatestNews__Description {
  margin: 0;
  margin-bottom: 1.6rem;

  font-size: 1.6rem;
  line-height: 1.41;
}

.LatestNews__Tags {
  --tags-text-size: 1.2rem;
}

.LatestNews__Date {
  display: block;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  font-size: 1.2rem;
  line-height: 0.98;
  text-transform: uppercase;
  color: var(--gray-100);
}

.LatestNews__Tag {
  --tags-padding-vertical: 0.6rem;
  --tags-padding-horizontal: 1.4rem;

  z-index: 1;
}

@media (min-width: 720px) {
  .LatestNews__Title {
    font-size: 2.8rem;
  }

  .LatestNews__Description {
    margin-bottom: 1.8rem;

    font-size: 2.2rem;
    line-height: 1.3;
  }

  .LatestNews__Tags {
    --tags-text-size: 1.8rem;
  }

  .LatestNews__Date {
    font-size: 1.8rem;
    line-height: initial;
  }
}

/* SEPARATOR */

.LatestNews__Separator {
  margin: 2.8rem -2rem;
  border: 1px solid var(--gray);
}

@media (min-width: 720px) {
  .LatestNews__Separator {
    margin: 3.8rem 0;
  }
}

/* INTERACTIONS */

.LatestNews__Image {
  transition: transform 250ms ease;
}

.LatestNews__Card:hover .LatestNews__Image,
.LatestNews__Card:focus-within .LatestNews__Image {
  transform: scale(1.05);
}

.LatestNews__Title {
  transition: color 250ms ease;
}

.LatestNews__Card:hover .LatestNews__Title,
.LatestNews__Card:focus-within .LatestNews__Title {
  color: var(--primary-light);
}

@media (prefers-reduced-motion: reduce) {
  .LatestNews__Image {
    transition: none !important;
    transform: none !important;
  }
}

/* ASPECT RATIO FALLBACK */

@supports not (aspect-ratio: 1) {
  .LatestNews__Media::before {
    padding-top: calc(100% / 16 * 9);
  }
}


.SiteContent {
  padding-bottom: 3.8rem;
}

@media (min-width: 720px) {
  .SiteContent {
    padding-bottom: 4rem;
  }
}
